import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Cta from "../components/service/Cta";
import WorkCollaboration from "../components/about/WorkCollaboration";
import SwiperTabs from "../components/SwiperTabs";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import ContentCommon from "../components/hire/ContentCommon";
import Reports from "../components/hire/Reports";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Hire VueJs Developers`,
    text: "Get VueJS Expertise",
    content: "Hire VueJS Developers from us to design dynamic apps and attract end-users to your brand's goal.",
    list: [
      'Flexible Hiring Options',
      'Cross-functional Designer',
      'Time-Zone Friendly'
    ],
    linkText: 'Hire VueJs Developers',
    link: '/contact-us/'
  };
  const commonContent = {
    title: 'Hire Vue js Developer to Get Strategic Benefits',
    text: "Hire Vue Js Developer to design robust interfaces with its syntax and extensive tooling, resulting in highly functional, responsive, and intuitive apps.",
    list: [
      {
        title: 'Improved Engagement',
        text: "Our Vue Js developers for hire offer reusable components and streamline operations, which reduces development time and increases productivity."
      },
      {
        title: 'Rich User Interfaces',
        text: 'Hire Vue.js developer to provide a creative solution for your business by leveraging its rich plugins, community-driven tools, and frameworks.'
      },
      {
        title: 'Enhanced Performance',
        text: 'Hire VueJS Developer who helps create visually interactive, functional, and responsive websites that improve user experience.'
      }
    ],
    image: ["hire-content-vue.webp"],
    titleText: 'Dedicated VueJS Developers',
    alt: ' Benefits of hiring Vue.js developers'
  }
  const commonContent2 = {
    title: 'Hire Dedicated Vue JS Developers from Octet',
    text: "Leading companies trust Octet Design Studio to hire VueJS Developer to acquire top design expertise with industry-specific experience.",
    list: [
      {
        title: 'Hire VueJS Developer In 48 Hours',
        text: 'Hire dedicated Vue js Developers to ensure swift recruitment processes, bringing aboard and hiring the best mobile app designers within 48 hours.'
      },
      {
        title: 'Transparent Reporting',
        text: 'Stay in control with detailed timesheet reports from our Vue js developers for hire to track progress and productivity.'
      },
      {
        title: '160 hours of Dedicated Work',
        text: 'Our Vue js developers for hire commit 160 hours of focused, high-quality work, ensuring productivity and results.'
      },
      {
        title: 'Seamless Collaboration',
        text: 'Hire dedicated Vue js Developers and enjoy seamless collaboration with a dedicated manager who oversees your design needs and ensures a smooth workflow.'
      }
    ],
    image: ["hire-content-2-vue.webp"],
    titleText: 'Hire Vue.js developers',
    alt: 'Hire Vue js developers from a leading Nuxt.js development company'
  }
  const commonContent3 = {
    title: `3 Steps to Hire VueJS Developer`,
    text: "Experience the Octet advantage by hiring VueJS developers in three easy steps.",
    list: [
      {
        title: 'Step 1: Share Your Requirements',
        text: `Provide us with your project requirements, and we will share the resumes of our developers for hire who best match your needs and business goals.`
      },
      {
        title: 'Step 2: Interview Designers',
        text: "Interview and evaluate our skilled developers to select the most suitable candidate for your project's vision and goals."
      },
      {
        title: 'Step 3: Onboard And Begin',
        text: "Once you close and hire Vue js developer, onboard them within 48 hours so they can dive into your project with their expertise and dedication."
      },
    ],
    image: ["hire-content-3-vue.webp"],
    titleText: 'Hire VueJS developer',
    alt: 'Simple steps to hire VueJS developers from Octet'
  }
  // const commonContent4 = {
  //   title: 'Hire Designers As Per Your Requirement',
  //   text: "At Octet, you have the freedom to choose your designer with flexible recruitment options. Select the hiring model that best suits your needs.",
  //   list: [
  //     {
  //       title: 'Hire on Fixed Cost Basis',
  //       text: "Get your project completed within a predetermined budget, ensuring cost-effectiveness and transparency."
  //     },
  //     {
  //       title: 'Hire on Time & Material Basis',
  //       text: "Flexibility is key with this model, pay for the actual hours worked and materials used, ideal for dynamic projects."
  //     },
  //     {
  //       title: 'Hire as a Full-time',
  //       text: "Secure a dedicated designer for ongoing projects, providing consistent support and expertise as part of your team."
  //     },
  //   ],
  //   image: ["hire-content-4.webp"],
  //   titleText: 'Hire UI UX Designer Flexibly',
  //   alt: 'Flexible Hiring Models to Hire UI UX Designers from our design agency'
  // }
  const cta = {
    title: `Got an Idea for your Next Web Project?`,
    contactText: 'Contact Us'
  };
  const content = [
    {
      title: "eCommerce VueJS Developers",
      content: "Our VueJS experts use various tools and software to develop apps that meet users' needs and help convert visitors into customers. Hire dedicated Vue js developers to create an enjoyable and seamless shopping experience for customers, which can increase sales and customer loyalty.",
    },
    {
      title: "VueJS Developer for SaaS",
      content:
        "SaaS Vue developers specialize in SaaS platforms and create visually appealing and user-friendly designs for web and mobile applications. Hire VueJS developer who will be creative problem solvers and develop beautiful and functional apps and websites that enhance the user experience.",
    },
    {
      title: "Enterprise VueJS Developer",
      content: "Hire Vue JS developers who will allow you to deliver scalable solutions for large-scale operations. These developers will address intricate workflows and compliance needs with precision and user-centric strategy. Our Vue js developers for hire will work closely with developers and product managers to ensure the final mobile app design meets the enterprise's and its users' needs.",
    },
  ];
  const tabsData = {
    title: `Hire VueJs Developers for Impecable Apps`,
    des: `Hire VueJS developer equipped with the latest development tools and methodologies to take a current approach and adhere to industry best practices.`,
    tabs: ["Single Page App ", "Progressive Web App Development", "Mobile App Development", "Server-Side Rendering"],
    slides: [
      {
        title: 'Single Page App',
        desc1: "Hire VueJS Developer to create web applications that load a single HTML page and dynamically update content as the user interacts with the app.",
        desc2: 'Our Vue js developers for hire utilize a unique approach to create a smooth and fast user experience.',
        list: [
          'Ability to Reduce Server Load',
          'Delivering Seamless Experience',
          'Expertise In Faster Loading Times'
        ]
      },
      {
        title: 'Progressive Web App Development',
        desc1: "Hire dedicated Vue JS developers to combine the best of web and mobile apps. Our developers use web technologies and offer a native app-like experience, including offline access and push notifications.",
        // desc2: 'Enhance your brand’s online presence with a focus on user-friendly, innovative design solutions tailored to your unique web UI UX needs.',
        list: [
          'Improving Speed & Performance',
          'Expertise in Offline Functionality',
          'Boosting SEO Friendliness'
        ]
      },
      {
        title: 'Mobile App Development',
        desc1: "Using Vue Native, our Vue js developers for hire create mobile applications that work on both iOS and Android platforms using Vue.js syntax and structure.",
        // desc2: 'Our UI UX experts create visually appealing mobile designs that ensure conciseness and efficiency to deliver a better user experience.',
        list: [
          'Delivering Secure Transactions',
          'Expertise in User-friendly Interfaces',
          'Providing Seamless Integration'
        ]
      },
      {
        title: 'Server-Side Rendering',
        desc1: "Hire Vue.js developers for rendering web pages on the server instead of the client. Our Vue js developers for hire utilize a framework based on Vue.js that makes SSR implementation easier and more efficient.",
        // desc2: "Our UI UX designers will create workflows and optimize user experience for desktop devices to meet users' needs and expectations.",
        list: [
          'Enhancing Security',
          'Simplifying Social Media Sharing',
          'Fastening Initial Page Loads'
        ]
      }
    ],
    altOne: 'Single Page Applications',
    titleOne: 'Single Page Applications',
    altTwo: 'Progressive Web App Development',
    titleTwo: 'Progressive Web App Development',
    altThree: 'Mobile App Development',
    titleThree: 'Mobile App Development',
    altFour: 'Server-Side Rendering',
    titleFour: 'Server-Side Rendering',
    images: [
      'our-ui-ux.webp',
      'our-ui-ux-2.webp',
      'our-ui-ux-3.webp',
      'our-ui-ux-4.webp'
    ]
  };
  const projectData = [
    {
      title: "Landscape Pro",
      desc: "Simplifying Crew Management Process",
      Industry: "SaaS",
      firstAlt: "UI UX Design of a Schedule Screen to Manage Property",
      firstTitle: "Schedule Screen UI Design",
      secondAlt: "UI UX Design to Manage and Track User's Status",
      secondTitle: "UI Screen to Manage Users",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Design Audit",
          link: "/design-audit/",
        },
      ],
      link: "/project/landscape-pro/",
      images: ["landscape-pro.webp", "landscape-pro-2.webp", "landscape-pro-3.webp"]
    },
    {
      title: "Survey2Connect",
      desc: "Streamlining Survey Collection Solution",
      Industry: "Enterprise",
      firstAlt: "UI UX Design To Create Survey",
      firstTitle: "Survey Creation Screen",
      secondAlt: "Ui Design To Create Questionnaire For Survey",
      secondTitle: "Questionnaire For Survey",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/survey2connect/",
      images: ["survey.webp", "survey-2.webp", "survey-3.webp"],
    },
    {
      title: "Elastic Sign",
      desc: "Efficient Design for Secure Document Signing",
      Industry: "SaaS",
      firstAlt: "UI Design to View all the Documents and their Status",
      firstTitle: "Manage Documents UI Design",
      secondAlt: "UI UX Design to Sign Documents Digitally",
      secondTitle: "UI Design to Sign Digital Documents",
      thirdAlt: "UI Screen to draw, upload or type your own signature",
      thirdTitle: "Create Your Own Signature",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "React Development",
          link: "/reactjs-development/",
        },
      ],
      link: "/project/elastic-sign",
      images: ["elastic-sign.webp", "elastic-sign-2.webp", "elastic-sign-3.webp"]
    },
    {
      title: "Unilogix App",
      desc: "Comprehensive Logistic Analytics Suite",
      Industry: "Logistics",
      firstAlt: "Mobile UI UX Screen Of Logistics Insight Application",
      firstTitle: "Logistics Services List",
      secondAlt: "Order And Warehouse Details",
      secondTitle: "Order Details",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/logistics-insight-app-design/",
      images: ["uni-logics.webp", "uni-logics-2.webp", "uni-logics-3.webp"],
    },
    {
      title: "Ship Delight",
      desc: "User-Centric Logistic Website Redesign",
      Industry: "Logistics",
      firstAlt: "UI UX Dashbaord To Check The Real Time Shipment Status",
      firstTitle: "Real Time Shipment Status",
      secondAlt: "UI Dashboard To Check The Performance Status",
      secondTitle: "NDR Status",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Wordpress Development",
          link: "/wordpress-development/",
        },
      ],
      link: "/project/shipdelight/",
      images: ["ship-delight-web.webp", "ship-delight-web-2.webp", "ship-delight-web-3.webp"],
    },
  ];
  const lastLength = projectData;
  const itemsList = [
    {
      title: '200+',
      text: 'Successful Projects '
    },
    {
      title: '30+',
      text: 'Experienced Designers'
    },
    {
      title: '80+',
      text: 'Satisfied Clients'
    }
  ];
  const reports = {
    title: `Hire Designers from <span class="h1-span">Octet vs Others</span>`,
    text: "Choosing Octet means efficient execution and a speedier ROI. Hire dedicated Vue JS developers passionate and committed to developing captivating apps, websites, and responsive experiences.",
    listHead: [
      'Aspects',
      'Octet’s UI UX Designers',
      'Other Designers'
    ],
    list: [
      {
        title: 'Hiring Time',
        first: '2 Days',
        second: 'Long Lead Time'
      },
      {
        title: 'Hiring Model',
        first: 'Flexible Options',
        second: 'Limited Options'
      },
      {
        title: 'Timezone',
        first: 'Overlap',
        second: 'No Overlap'
      },
      { title: 'VueJs Expertise', second:'Uncertain' },
      { title: 'Pre-screen Candidate' },
      { title: 'NDA Guarantee' },
      { title: '100% Replacement' },
      { title: 'Highend Systems' },
      { title: 'Free Trial'},
      { title: 'Hiring fees', first: 'true', second: 'Uncertain' },
      { title: 'Hidden Charges', first: 'true', second: 'Uncertain' },
    ]
  };
  const ourData = {
    title: 'Hear From Our Clients',
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['visa.webp'],
        stars: 4,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      }
    ]
  };
  const Questions = [
    "Why should you Hire Dedicated Vue js Developers from Octet?",
    "How can you Hire VueJS Developer from Octet?",
    "Which companies Hire Vue JS Developers?",
    "Does your Vue js Developers for Hire come with a trial period?",
    "How does our transparent pricing system work?"
  ];
  const faqData = [
    {
      para: [
        `Octet boasts a skilled, creative, and functional team of developers who specialize in crafting engaging and functional interfaces.`,
        `Our developers have a proven track record of delivering user-centric websites across diverse industries. They are well-versed in design principles and keep themselves updated with the latest design technologies and trends.`,
        `Hire dedicated Vue js developers from Octet to ensure your product remains relevant and competitive.`
      ]
    },
    {
      para: [
        `Hiring Vue js developer from Octet involves just 3 easy steps:`,
        `<strong><h4>Share Your Requirements:</h4></strong>`,
        `Tell us about your project needs, including the scope, objectives, target audience, and specific preferences or technical requirements. This will help us find the most suitable developers to meet your needs and expectations.`,
        `<strong><h4>Interview Designers:</h4></strong>`,
        `Once we understand your needs, we will share a list of developers who match your project needs.`,
        `You can then interview and evaluate our developers to assess their skills, experience, and creativity.`,
        `Choose the candidate who best matches your project goals.`,
        `<strong><h4>Onboard and Begin:</h4></strong>`,
        `After hiring Vue js developer from us, onboard them to your project team and provide them access to necessary resources.`,
      ]
    },
    {
      para: [
        `VueJS Experts are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.`,
        `Whether designing mobile apps, building websites, software interfaces, or consumer electronics, our developers can enhance usability, functionality, and visual appeal for different companies.`,
      ]
    },
    {
      para: [
        `Hiring VueJS Developers from Octet comes with a complimentary 3-day trial period. This allows our clients to experience our approach and see how we can help them find the right candidate for their company.`,
      ]
    },
    {
      para: [
        `At our company, we believe in transparency regarding pricing. We understand the importance of knowing what you're paying for without any surprises, so we offer a clear breakdown of costs for all our products and services.`,
        `Our pricing model is designed to eliminate hidden fees and provide customizable solutions tailored to your unique needs. By hiring our developers, you can trust that you're getting the most value for your money.`,
      ]
    },
  ];
  const commonContent5 = {
    title: "Hire Vue.js Developers for Aligned Solutions",
    para: 'Hire Vue.js developers who offer end-to-end services to build lightweight, performant, and result-driven VueJS apps that help your business flourish.',
    content: [
      {
        title: "Custom App Development",
        text: "We develop unique VueJs applications to meet your company's demands and ambitions.",
      },
      {
        title: "Mobile Application Development",
        text: "We develop high-performance, functional, and engaging mobile apps to captivate your target audience.",
      },
      {
        title: "Third-Party Integration",
        text: "Hire Vue.js Developers to integrate third-party services into your projects to improve your web app, mobile app functionality.",
      },
      {
        title: "Interactive UI Development",
        text: "Hire VueJS Developer to improve the user interface and provide an engaging and intuitive experience through innovative VueJS services.",
      },
      {
        title: "Portal Development Solutions",
        text: "Hire dedicated Vue js Developers for comprehensive portal Vuejs development services and build strong and dynamic online platforms.",
      },
      {
        title: "Real-time Application",
        text: "Our Vue js developers for hire provide real-time solutions by using Vue.js expertise to build dynamic and interactive user experiences.",
      },
    ],
  };
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
         {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Why should you Hire Dedicated Vue js Developers from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Octet boasts a skilled, creative, and functional team of developers who specialize in crafting engaging and functional interfaces. 
          
          Our developers have a proven track record of delivering user-centric websites across diverse industries. They are well-versed in design principles and keep themselves updated with the latest design technologies and trends. 
          
          Hire dedicated Vue js developers from Octet to ensure your product remains relevant and competitive."
              }
            },{
              "@type": "Question",
              "name": "How can you Hire VueJS Developer from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hiring Vue js developer from Octet involves just 3 easy steps:
          
          Share Your Requirements:
          Tell us about your project needs, including the scope, objectives, target audience, and specific preferences or technical requirements. This will help us find the most suitable developers to meet your needs and expectations.
          
          Interview Designers:
          Once we understand your needs, we will share a list of developers who match your project needs.
          You can then interview and evaluate our developers to assess their skills, experience, and creativity.
          Choose the candidate who best matches your project goals.
          
          Onboard and Begin:
          After hiring Vue js developer from us, onboard them to your project team and provide them access to necessary resources."
              }
            },{
              "@type": "Question",
              "name": "Which companies Hire Vue JS Developers?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "VueJS Experts are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.
          
          Whether designing mobile apps, building websites, software interfaces, or consumer electronics, our developers can enhance usability, functionality, and visual appeal for different companies."
              }
            },{
              "@type": "Question",
              "name": "Does your Vue js Developers for Hire come with a trial period?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hiring VueJS Developers from Octet comes with a complimentary 3-day trial period. This allows our clients to experience our approach and see how we can help them find the right candidate for their company."
              }
            },{
              "@type": "Question",
              "name": "How does our transparent pricing system work?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "At our company, we believe in transparency regarding pricing. We understand the importance of knowing what you're paying for without any surprises, so we offer a clear breakdown of costs for all our products and services. 
          
          
          Our pricing model is designed to eliminate hidden fees and provide customizable solutions tailored to your unique needs. By hiring our developers, you can trust that you're getting the most value for your money."
              }
            }]
          }                                       
        `}
      </script>
    </Helmet>
      <Layout hire="Hire VueJs Developers">
        <div>
          <HireBanner data={banner} pageName="Hire UI/UX Designer" />
          <section className="py-[40px]">
            <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="lg:h-[100px] md:h-[80px] h-[60px]">
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <SwiperTabs data={tabsData} normal light />
          <CommonServiceComponent data={commonContent5} mode={"light"} hire />
          <ContentCommon dark data={commonContent} />
          <Reports data={reports} />
          <ContentCommon data={commonContent2} dark />
          <WorkCollaboration grey content={content} title={`Get Industry’s Top Vue js Developers for Hire`} text={``} />
          <ContentCommon data={commonContent3} big reverse />
          {/* <ContentCommon data={commonContent4} big /> */}
          {/* <OurWorkslef data={ourData} /> */}
          <Cta lgtextClass="lg:text-[50px]" data={cta} />
          <Faq section Questions={Questions} faqData={faqData} des={'Explore common queries about our VueJS developers, hiring VueJS developer, pricing, and processes to gain clarity and confidence in your decision-making journey.'} />
        </div>
      </Layout>
    </>
  );
};

export default HirePage;
export const Head = () => (
  <Seo
    title="Hire VueJS Developers | Vue js Developers for Hire"
    description="Hire VueJS DeveloperS to create cutting-edge, live online applications. Get access to the team of our Vue JS developers for hire to create superior solutions."
  />
);